import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImg } from "../helpers/cloudinary";
import '../styles/components/tabs.scss';
import { ListGroup } from "react-bootstrap";

export default function TabModel({ tab, model }) {
  const char = model.caracteristics.find((elem) => elem.title.toLowerCase() === tab.toLowerCase());

  return (
    <div className="tab-model">
      <div className="chars">
        {
          char.carousel.map((caro, ind) => {
            return (
              <div key={ind} className="carousel-info">
                <AdvancedImage cldImg={cloudinaryImg(caro.img)} />
                <div className="infotest-carousel">
                  <p>{caro.text}</p>
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="infotab">
        {
          char.texts?.map((text, ind_text) => {
            const parseText = text.split('. ')
            return (
              <ListGroup key={ind_text} variant="flush">
                {
                  parseText.map((elem, ind) => {
                    return (
                      <ListGroup.Item key={`${ind_text}-${ind}`}>{elem}</ListGroup.Item>
                    )
                  })
                }
              </ListGroup>
            )
          })
        }
      </div>
    </div>
  );
}
