import '../../styles/components/models.scss';
import { useEffect, useState } from "react";
import ModelCard from "../../atomic/ModelCard";
import { models } from "../../helpers/constants";

export default function Models() {

  const [selectedModel, setSelectedModel] = useState(models[0].name);
  const [filteredOptions, setFilteredOptions] = useState(models[0].optionList);

  const handleModel = (modelName) => {
    setSelectedModel(modelName);
    const selectedOptions = models.find((model) => model.name === modelName)?.optionList;
    setFilteredOptions(selectedOptions || []);
  }

  useEffect(() => {
  }, [selectedModel, filteredOptions]);

  return (
    <div className="models">
      <h4>Nuestros modelos</h4>
      <div className="models-render">
        <div className="index-model">
          <ul className="indexes">
            {models.map((mod, ind) => (
              <li
                key={ind}
                onClick={() => handleModel(mod.name)}
                className={mod.name === selectedModel ? 'selected-index' : ''}
              >
                {mod.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="models-cards">
          <div className="models-cuadricula">
            {filteredOptions.map((model, index) => {
              return <ModelCard key={index} name={model.name} img={model.image} redirectUrl={model.href} />
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
