import Carousel from 'react-bootstrap/Carousel';
import '../styles/components/carousel.scss';
import { AdvancedImage } from '@cloudinary/react';
import { cloudinaryImg } from '../helpers/cloudinary';
import { useLocation } from 'react-router-dom';

export default function CarouselImages({ carouselImg }) {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sectionParam = params.get('section');
  console.log(sectionParam)

  return (
    <Carousel
      controls={false}
      indicators={false}
      interval={2500}
    >
      {
        carouselImg.map((elem, index) => {
          return (
            <Carousel.Item key={index}>
              <AdvancedImage cldImg={cloudinaryImg(elem.img)} alt={elem.name} />
              {
                !sectionParam &&
                <Carousel.Caption>"Foto no contractual, los modelos de las imagenes pueden diferir de los comercializados en nuestro pais"</Carousel.Caption>
              }
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  )
}