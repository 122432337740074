export const navItems = [
  {
    name: 'Nosotros',
    options: [
      {
        name: 'Empresa',
        optionList: [
          {
            name: 'Trayectoria',
            href: '/empresa?section=trayectoria'
          },
          {
            name: 'Clientes',
            href: '/empresa?section=clientes'
          },
        ]
      }
    ]
  },
  {
    name: 'Modelos',
    options: [
      // {
      //   name: 'Autos',
      //   optionList: [
      //     {
      //       name: 'I10',
      //       href: '/modelos/i10'
      //     },
      //   ]
      // },
      {
        name: 'Suv',
        optionList: [
          {
            name: 'Creta',
            href: '/modelos/creta'
          },
          {
            name: 'New Creta',
            href: '/modelos/newcreta'
          },
          // {
          //   name: 'Tucson',
          //   href: '/modelos/tucson'
          // },
          {
            name: 'Santa Fe',
            href: '/modelos/santafe'
          }
        ]
      },
      {
        name: 'Comercial',
        optionList: [
          {
            name: 'Staria',
            href: '/modelos/staria'
          },
        ]
      },
      // {
      //   name: 'Deportivo',
      //   optionList: [
      //     {
      //       name: 'Genesis',
      //       href: '/modelos/genesis'
      //     }
      //   ]
      // }
    ]
  },
  {
    name: 'Usados',
    options: [
      {
        name: 'Verificados',
        optionList: [
          {
            name: 'Todo',
            href: '/usados'
          },
          // {
          //   name: 'Camionetas',
          //   href: '/usados'
          // },
        ]
      }
    ]
  },
  {
    name: 'Sucursales',
    options: [
      {
        name: 'Concesionario',
        optionList: [
          {
            name: 'San Isidro',
            href: '/sucursal?section=concesionario'
          }
        ]
      },
      {
        name: 'Taller Oficial',
        optionList: [
          {
            name: 'Troncos del Talar',
            href: '/sucursal?section=taller'
          }
        ]
      }
    ]
  }
]

export const carouselImg = [
  {
    name: 'img1',
    img: 'Carousel/newcretacarr_tfxvug'
  },
  {
    name: 'img2',
    img: 'Carousel/stariacarr_wov0cz'
  },
  {
    name: 'img3',
    img: 'Carousel/tucsoncarr_tfomua'
  }
  // {
  //   name: 'img4',
  //   img: 'Carousel/carousel4_dyerb0'
  // },
  // {
  //   name: 'img5',
  //   img: 'Carousel/carousel5_uq9mza'
  // },
  // {
  //   name: 'img6',
  //   img: 'Carousel/carousel6_wjncbo'
  // },
  // {
  //   name: 'img7',
  //   img: 'Carousel/carousel7_pl4wlr'
  // },
  // {
  //   name: 'img8',
  //   img: 'Carousel/carousel8_dhlhhh'
  // },
  // {
  //   name: 'img9',
  //   img: 'Carousel/carousel9_mtjpqn'
  // }
]

export const models = [
  // {
  //   name: 'Autos',
  //   optionList: [
  //     {
  //       name: 'I10',
  //       image: 'Models/grandi10-removebg-preview_tw9afn',
  //       href: '/modelos/i10'
  //     },
  //   ]
  // },
  {
    name: 'SUV',
    optionList: [
      {
        name: 'Creta',
        image: 'Models/ce9ad8576e315ec065b8f0c6a3c28e2c_atdkqt',
        href: '/modelos/creta'
      },
      {
        name: 'New Creta',
        image: 'Models/CRETA2022/currentImage-removebg-preview_pzrzkc',
        href: '/modelos/newcreta'
      },
      // {
      //   name: 'Tucson',
      //   image: 'Models/tucson-removebg-preview_a2ce0y',
      //   href: '/modelos/tucson'
      // },
      {
        name: 'Santa Fe',
        image: 'Models/santafe-removebg-preview_qcuhha',
        href: '/modelos/santafe'
      }
    ]
  },
  {
    name: 'Comercial',
    optionList: [
      {
        name: 'Staria',
        image: 'Models/STARIA/staria-removebg-preview_1_yfq4ur',
        href: '/modelos/staria'
      },
    ]
  },
  // {
  //   name: 'Deportivo',
  //   optionList: [
  //     {
  //       name: 'Genesis',
  //       image: 'Models/genesis_j4niqo',
  //       href: '/modelos/genesis'
  //     }
  //   ]
  // }
]

export const clients = [
  {
    name: 'Pablo',
    img: 'Clients/Untitled_design_3_jgpns3',
    opinion: '"Un amigo de la casa subiendose a un nuevo 0km, y en este caso entregada por el mismísimo JCF. Que la disfrutes Pablo y gracias por confiarnos la compra de tu nueva Creta."'
  },
  {
    name: 'Salva',
    img: 'Clients/Untitled_design_fdtx1x',
    opinion: '"En un sábado soleado, retiró su new creta 0km. Gracias por confiarnos la compra de tu nuevo SUV. Que la disfruten mucho!"'
  },
  {
    name: 'Jimena',
    img: 'Clients/Untitled_design_2_x7zfts',
    opinion: '"Que lindo jueves para retirar tu nueva Tucson. Con barbijo y todo, no puede disimular su alegría. Gracias por confiarnos la compra de tu nuevo vehículo!! Que la disfrutes!"'
  },
  {
    name: 'Florencia',
    img: 'Clients/Untitled_design_1_uqnblc',
    opinion: '"La sonrisa lo dice todo. Feliz retirando su kona 0km. Que la disfrutes mucho!"'
  }
]

export const concesionarioCarousel = [
  {
    name: 'img1',
    img: 'Concesionario/6_w0myjo'
  },
  {
    name: 'img2',
    img: 'Concesionario/IMG-20231221-WA0044_crtilq'
  },
  {
    name: 'img3',
    img: 'Concesionario/IMG-20231221-WA0036_yde0er'
  },
  {
    name: 'img4',
    img: 'Concesionario/IMG-20231221-WA0035_ylxmnh'
  }
]

export const tallerCarousel = [
  {
    name: 'img1',
    img: 'Taller/IMG_2766_mslyo6'
  },
  {
    name: 'img2',
    img: 'Taller/IMG_2769_xt6mfq'
  },
  {
    name: 'img3',
    img: 'Taller/92810290_217070473046331_7499587640325017697_n_u02zfl'
  },
  {
    name: 'img4',
    img: 'Taller/IMG_2767_ynjtq1'
  },
  {
    name: 'img5',
    img: 'Taller/152552179_454357299317218_4663887929879446276_n_lggqj3'
  },
]

export const models_description = [
  {
    id: 'creta',
    name: 'Creta',
    img: 'Models/CRETA2020/creta2020-destacados-confort-de-marcha_g8vwe6',
    images: [
      'Models/CRETA2020/creta2020-gallery-2_mgb8xu',
      'Models/CRETA2020/creta2020-gallery-3_e4danv'
    ],
    highlights: [
      {
        title: 'Confort de marcha',
        description: 'Su transmisión, distancia entre ejes, despeje del terreno y suspensión aseguran un andar suave y fluido.'
      },
      {
        title: 'Espacio Interior',
        description: 'Tanto las butacas delanteras como las traseras cuentan con gran habitabilidad y comodidad para los pasajeros.'
      },
      {
        title: 'Calidad Global',
        description: 'Cuenta con los máximos estándares de calidad que posee Hyundai a nivel mundial.'
      },
      {
        title: 'Conectividad',
        description: 'Equipada con pantalla inteligente de 7 pulgadas con Apple CarPlay y Android Auto.'
      }
    ],
    caracteristics: [
      {
        title: 'Diseño',
        texts: [
          'Creta proyecta autoridad. Las líneas suaves del capot y su paragolpes voluminoso marcan una impactante primera impresión.',
          'La parilla cromada hexagonal de última generación evoca un avión y permite una conducción más dinámica. Espacio de almacenamiento en consola central. Ofrece un lugar práctico y útil.',
          'Porta objetos en la puerta delantera. Los eficientes espacios son ideales para guardar diarios, documentos y también contener una botella de 1L.'
        ],
        carousel: [
          {
            name: 'interior',
            img: 'Models/CRETA2020/creta2020-disenio-interior_nmx75o',
            title: 'Terminaciones premium',
            text: 'Terminaciones premium'
          },
          {
            name: 'manijas',
            img: 'Models/CRETA2020/creta2020-disenio-manijas-cromadas_cnrnvd',
            text: 'Manijas de puerta cromadas'
          },
          {
            name: 'luces',
            img: 'Models/CRETA2020/creta2020-disenio-luces-antinieblas_ft5hc3',
            text: 'Luces antiniebla y DRL integrados'
          },
          {
            name: 'techo',
            img: 'Models/CRETA2020/creta2020-disenio-antena-brillante_mltsta',
            text: 'Barras longitudinales en el techo'
          },
          {
            name: 'llantas',
            img: 'Models/CRETA2020/creta2020-disenio-llantas-17-pulgadas_l6acva',
            text: 'Llantas de 17 pulgadas'
          }
        ]
      },
      {
        title: 'Performance',
        texts: [
          'Creta te lleva aún más allá. El poderoso motor ofrece un auténtico placer al manejar. Suave y confortable, proporciona alegría en todo momento.',
          'Produce 130 caballos de fuerza con bajo consumo de combustible, satisfaciendo las necesidades de un conductor exigente. Cuenta con el moderno sistema Stop-Go y E-start de arranque en frío, que prescinde de un depósito de gasolina adicional, lo que mejora el rendimiento del motor y reduce las emisiones contaminantes.',
          'La transmisión automática de 6 velocidades otorga respuestas de alta precisión, cambios suaves y una durabilidad excepcional. Con la aplicación de acero avanzado de alta resistencia se logró mayor durabilidad con menor corrosión y a la vez una estructura ligera.'
        ],
        carousel: [
          {
            name: 'caja',
            img: 'Models/CRETA2020/creta-performance-caja-automatica_negrft',
            text: 'Transmisión automática de 6 velocidades'
          },
          {
            name: 'motor',
            img: 'Models/CRETA2020/creta2020-performance-motor_izbphc',
            text: 'Gamma 1.6 - 4 válvulas por cilindro'
          },
          {
            name: 'carroceria',
            img: 'Models/CRETA2020/creta-acero-avanzado_snquo7',
            text: 'Acero Avanzado de Alta Resistencia (AHSS)'
          },
        ]
      },
      {
        title: 'Seguridad',
        texts: [
          'Más equipamiento de seguridad. Los caminos pueden cambiar pero su vehículo debe ser estable en cualquier situación. Por eso nos enfocamos a la seguridad y la estabilidad.',
          'El sistema de 6 airbag comprende dos airbags frontales, dos airbags de impacto lateral en la parte delantera y dos airbags de cortina que corren a lo largo por ambos lados. Sistema de asistencia para estacionamiento trasero',
          'Faros inteligentes, permite que los faros permanezcan encendidos durante un tiempo limitado después de que el automóvil se cierre. Altura ajustable del cinturón de seguridad'
        ],
        carousel: [
          {
            name: 'hac',
            img: 'Models/CRETA2020/creta-seguridad-HAC_pauyuf',
            text: 'Control de arranque en pendiente (HAC)'
          },
          {
            name: 'esc',
            img: 'Models/CRETA2020/creta-seguridad-ESC_bw1tr7',
            text: 'Control electrónico de estabilidad (ESC)'
          },
          {
            name: 'abs',
            img: 'Models/CRETA2020/creta-seguridad-ABS_kthgsq',
            text: 'Sistema de frenos anti-bloqueo con EBD'
          },
          {
            name: 'sbl',
            img: 'Models/CRETA2020/creta-seguridad-SBL_huh6of',
            text: 'Luces de curva (SBL)'
          }
        ]
      },
      {
        title: 'Confort',
        texts: [
          'Detalles que mejoran el confort. Todos los elementos son perfectos para el tacto y forman un equilibrio que ayudarán a disfrutar un viaje mejor, más seguro y agradable.',
          'Proporciona un sonido estéreo delicado y dinámico a través de 4 parlantes de alto rendimiento, incluidos 2 altavoces de agudos que permiten disfrutar una alta calidad mediante la intensificación de graves.',
          'Apoyacabezas ajustables. Luz en el baúl. Salidas trasera de aire acondicionado. Volante ajustable'
        ],
        carousel: [
          {
            name: 'parking',
            img: 'Models/CRETA2020/creta-confort-top-view-parking-rear-sensors-original_hedx51',
            text: 'Sensores de estacionamiento traseros'
          },
          {
            name: 'motor',
            img: 'Models/CRETA2020/creta-confort-soundwave-graphics-illustrated-around-original_aj9lxs',
            text: 'Sistema de sonido Premium'
          },
          {
            name: 'llave',
            img: 'Models/CRETA2020/creta2020-confort-smart-key_urtozh',
            text: 'Smart Key'
          },
          {
            name: 'boton',
            img: 'Models/CRETA2020/creta2020-confort-boton-arranque_bzdxxd',
            text: 'Botón de arranque'
          }
        ]
      }
    ]
  },
  {
    id: 'newcreta',
    name: 'New Creta',
    img: 'Models/CRETA2022/newcreta_bxafuz',
    images: [
      'Models/CRETA2022/newcreta3_dkx79n',
      'Models/CRETA2022/newcreta2_gmokvl',
      'Models/CRETA2022/newcreta5_rjhbte',
      'Models/CRETA2022/newcreta7_rjktyf'
    ],
    highlights: [
      {
        title: 'Más sofisticada.',
        description: 'Ya sea por su diseño único, su tecnología de punta, lujo que supera expectativas o su nivel de seguridad como nunca antes habías visto en Creta.'
      },
      {
        title: 'Mejora constante',
        description: 'El SUV que mejoró el segmento.'
      },
    ],
    caracteristics: [
      {
        title: 'Diseño',
        texts: [
          'Creta proyecta autoridad. Las líneas suaves del capot y su paragolpes voluminoso marcan una impactante primera impresión.',
          'La parilla cromada hexagonal de última generación evoca un avión y permite una conducción más dinámica.',

        ],
        carousel: [
          {
            name: 'espejos',
            img: 'Models/CRETA2022/newcreta14_vhymln',
            text: 'Espejos color carrocería con luz de giro'
          },
          {
            name: 'parrilla',
            img: 'Models/CRETA2022/newcreta13_bduox0',
            text: 'Parrilla hexagonal plateada con borde cromado'
          },
          {
            name: 'nuevodiseno',
            img: 'Models/CRETA2022/newcreta6_ohepjd',
            text: 'Diseño sofisticado'
          },
          {
            name: 'tapizados',
            img: 'Models/CRETA2022/newcreta4_hrnkpo',
            text: 'Tapizados de cuero'
          }
        ]
      },
      {
        title: 'Performance',
        texts: [
          'El poderoso motor ofrece un auténtico placer al manejar. Suave y confortable, proporciona alegría en todo momento.',
          'Motor naftero 1.5 Smarstream, de cuatro cilindros en línea, doble árbol de levas a la cabeza y doble distribución variable D-CVVT.',
          'Desarrolla 115 CV a 6.300 rpm y 14,7 kgm de torque a 4.500 rpm. Se acopla a una caja automática CVT con 8 velocidades simuladas y tracción delantera.'
        ],
        carousel: [
          {
            name: 'caja',
            img: 'Models/CRETA2022/newcreta15_eutqsi',
            text: 'Levas de cambios en el volante'
          },
          {
            name: 'caja',
            img: 'Models/CRETA2020/creta-performance-caja-automatica_negrft',
            text: 'Transmisión automática de 6 velocidades'
          },
          {
            name: 'motor',
            img: 'Models/CRETA2020/creta2020-performance-motor_izbphc',
            text: 'Gamma 1.6 - 4 válvulas por cilindro'
          },
          {
            name: 'carroceria',
            img: 'Models/CRETA2020/creta-acero-avanzado_snquo7',
            text: 'Acero Avanzado de Alta Resistencia (AHSS)'
          },
        ]
      },
      {
        title: 'Seguridad',
        texts: [
          'Los caminos pueden cambiar pero su vehículo debe ser estable en cualquier situación. Por eso nos enfocamos a la seguridad y la estabilidad.',
          '6 airbags (frontales, laterales y de cortina), frenos con ABS/EBD/BA, control de tracción, control de estabilidad, asistencia al arranque en pendientes, monitoreo de presión de los neumáticos, sensor de ángulo ciego.',
          'Cámara con visión de 360º, anclajes Isofix, cierre automático de puertas en rodaje, alerta de ocupante trasero, luces antiniebla delanteras y alerta de hielo en calzada.'
        ],
        carousel: [
          {
            name: 'airbag',
            img: 'Models/CRETA2022/newcreta9_dlgu5j',
            text: '6 airbags'
          },
          {
            name: 'punto ciego',
            img: 'Models/CRETA2022/newcreta8_omg16u',
            text: 'Monitoreo de punto ciego'
          },
          {
            name: 'esp',
            img: 'Models/CRETA2022/newcreta10_v6ny7o',
            text: 'Programa electronico de estabilidad (ESP)'
          },
          {
            name: 'neumaticos',
            img: 'Models/CRETA2022/newcreta11_typ9z1',
            text: 'Alerta presion neumaticos'
          }
        ]
      },
      {
        title: 'Confort',
        texts: [
          'Todos los elementos son perfectos para el tacto y forman un equilibrio que ayudarán a disfrutar un viaje mejor, más seguro y agradable.',
          'Climatizador automático digital, sistema multimedia BlueMedia con pantalla táctil de 10” compatible con Android Auto y Apple CarPlay, cargador inalámbrico, butaca del conductor con ventilación, levas de cambio, volante multifunción revestido en cuero, selector de modos de manejo.',
          'Freno de mano eléctrico, tapizado de cuero marrón Wengue, arranque por botón, salidas de aire en plazas traseras, espejos eléctricos, levantavidrios eléctricos con one touch, control de velocidad crucero.'
        ],
        carousel: [
          {
            name: 'butaca',
            img: 'Models/CRETA2022/newcreta18_hhnphu',
            text: 'Butaca de conductor ventilada'
          },
          {
            name: 'parking',
            img: 'Models/CRETA2022/newcreta19_tvsojc',
            text: 'Freno de mano eléctrico'
          },
          {
            name: 'cargador',
            img: 'Models/CRETA2022/newcreta16_gadptf',
            text: 'Cargador inalambrico'
          },
          {
            name: 'boton',
            img: 'Models/CRETA2022/newcreta17_qnq7uw',
            text: 'Botón de arranque'
          }
        ]
      }
    ]
  },
  {
    id: 'staria',
    name: 'Staria',
    img: 'Models/STARIA/rendimiento_traccion_lrwqnp',
    images: [
      'Models/STARIA/destacados_slider_1_qziui6',
      'Models/STARIA/destacados_slider_5_ge4gnz',
      // 'Models/STARIA/destacados_cabecera_cta_g69dr4'
    ],
    highlights: [
      {
        title: 'Entra en el espacio',
        description: 'En este espacio, las posibilidades son ilimitadas. Súbete a la All-new STARIA y experimenta una comodidad y versatilidad excepcionales, respaldadas por los más altos niveles de seguridad y conectividad.'
      },
      {
        title: 'All-new STARIA: 11 plazas al nivel de lujo.',
        description: 'Elegantemente futurista, la STARIA hace más que solo establecer una nueva estética de diseño: está redefiniendo el segmento de MPV de lujo para destacarse en una clase propia.'
      },
      {
        title: 'Garantía de kilometraje de 100,000 km por 3 años.',
        description: 'Como todos los Hyundai, la nueva STARIA está construida con los más altos estándares de calidad posibles. '
      }
    ],
    caracteristics: [
      {
        title: 'Diseño',
        texts: [
          'Elegante y puro, el diseño aerodinámico de la All-New STARIA se define por su elegante silueta y los elementos de diseño futuristas.',
          'Creado con una nueva metodología de diseño “de adentro hacia afuera”, se comenzó con el espacio interior y lo expandimos a la belleza exterior. Llamativamente futurista, el elegante diseño exterior de la STARIA utiliza líneas minimalistas para lograr máximo impacto visual.',
          'Definido por una curva fluida que corre desde el frente hacia atrás, inspirado por la aureola que ilumina el horizonte del planeta tierra durante el amanecer desde la perspectiva del espacio. La estética de nave espacial se refleja en sus amplias ventanas panorámicas y cinturones bajos, resaltando la apertura del interior.'
        ],
        carousel: [
          {
            name: 'frente',
            img: 'Models/STARIA/design_the_front_wtkq6l',
            text: 'Diseño de luces y parrilla BOLD'
          },
          {
            name: 'distintivo',
            img: 'Models/STARIA/destacados_slider_1_qziui6',
            text: 'Diseño muy distintivo'
          },
          {
            name: 'ruedas',
            img: 'Models/STARIA/design_exterior_front_back_ntdfdv',
            text: 'Elegantes ruedas de 18" ó 17"'
          },
          {
            name: 'asientos',
            img: 'Models/STARIA/interior_asientos_1_tv70ec',
            text: '11 plazas con cuatro filas de asientos'
          }
        ]
      },
      {
        title: 'Performance',
        texts: [
          'La All-new STARIA puede llevarte donde quieras ir con una poderosa eficiencia. El turbocompresor refrigerado por aire de alta eficiencia del motor diésel tiene un rendimiento mejorado del compresor y la rueda de la turbina para un mejor rendimiento de par a baja velocidad.',
          'El motor diésel de 2,2 l tiene una potencia máxima de 177 CV. Caja de cambios automática con opción accionable por botón.',
          'Seleccione el modo Sport para un rendimiento mejorado, el modo Eco para obtener la máxima eficiencia de combustible o el modo normal para un rendimiento regular con buena eficiencia.'
        ],
        carousel: [
          {
            name: 'motor',
            img: 'Models/STARIA/rendimiento_motor_zyyakq',
            text: 'El motor'
          },
          {
            name: 'suspension',
            img: 'Models/STARIA/transmision_caja_ar6n8a',
            text: 'Transmisión automática de 8 velocidade'
          },
          {
            name: 'motor',
            img: 'Models/STARIA/rendimiento_traccion_lrwqnp',
            text: 'Tracción en todas las ruedas HTRAC™'
          },
          {
            name: 'carroceria',
            img: 'Models/STARIA/rendimiento_modos_iwcwda',
            text: 'Modos de conducción'
          },
        ]
      },
      {
        title: 'Seguridad',
        texts: [
          'Con los mejores sistemas avanzados de asistencia al conductor y un paquete de seguridad activa con una gama de características innovadoras, STARIA ofrece aún más tranquilidad. Todos los asientos vienen con cinturones de seguridad de tres puntos y reposacabezas.',
          'La All-new STARIA cuenta con asistencia de seguimiento de carril. Asistencia para evitar colisiones en punto ciego. Asistencia de conducción en carretera.',
          'Asistencia para evitar colisiones de tráfico cruzado trasero. Asistencia para evitar colisiones frontales. Asistente de salida segura.'
        ],
        carousel: [
          {
            name: 'lfa',
            img: 'Models/STARIA/ezgif.com-video-to-gif_sbujqn',
            text: 'Asistencia de seguimiento de carril'
          },
          {
            name: 'bca',
            img: 'Models/STARIA/destacados_caracteristicas_3_rixsvc',
            text: 'Asistencia para evitar colisiones de tráfico cruzado trasero'
          },
          {
            name: 'abs',
            img: 'Models/STARIA/ezgif.com-video-to-gif_1_jqzv0f',
            text: 'Asistencia para evitar colisiones en puntos ciegos'
          },
          {
            name: 'sbl',
            img: 'Models/STARIA/ezgif.com-video-to-gif_2_fki5ae',
            text: 'Asistencia de conducción en carretera'
          },
          {
            name: 'sea',
            img: 'Models/STARIA/safety_4_ouqqpl',
            text: 'Asistente de salida segura'
          },
          {
            name: 'fca',
            img: 'Models/STARIA/safety_2_nhigyg',
            text: 'Asistencia para evitar colisiones frontales'
          }
        ]
      },
      {
        title: 'Confort',
        texts: [
          'Detalles que mejoran el confort. Todos los elementos son perfectos para el tacto y forman un equilibrio que ayudarán a disfrutar un viaje mejor, más seguro y agradable.',
          'Puerta corrediza eléctrica. Portón trasero eléctrico inteligente. Caja de cambios accionable por botón. Clúster totalmente digital. Pantalla táctil de 8”',
          'Cargador inalámbrico. Multiples puertos USB. BOSE premium sound.'
        ],
        carousel: [
          {
            name: 'parking',
            img: 'Models/CRETA2020/creta-confort-top-view-parking-rear-sensors-original_hedx51',
            text: 'Sensores de estacionamiento traseros'
          },
          {
            name: 'motor',
            img: 'Models/CRETA2020/creta-confort-soundwave-graphics-illustrated-around-original_aj9lxs',
            text: 'Sistema de sonido Premium'
          },
          {
            name: 'llave',
            img: 'Models/CRETA2020/creta2020-confort-smart-key_urtozh',
            text: 'Smart Key'
          },
          {
            name: 'boton',
            img: 'Models/CRETA2020/creta2020-confort-boton-arranque_bzdxxd',
            text: 'Botón de arranque'
          }
        ]
      }
    ]
  },
  {
    id: 'santafe',
    name: 'Santa Fe',
    img: 'Models/SANTAFE/santafe-destacados-disenio_a8qd01',
    images: [
      'Models/SANTAFE/santafe-gallery-1',
      'Models/SANTAFE/santafe-gallery-2_p11kdm',
      'Models/SANTAFE/santafe-gallery-3_xwa4im',
    ],
    highlights: [
      {
        title: 'Historia',
        description: 'Acompañando desde el primer momento las mejores anécdotas en familia. Y evolucionando en diseño, calidad y tecnología. Una historia que sigue escribiéndose.'
      },
      {
        title: 'Celebrar la diversidad.',
        description: 'Hay un auto diferente para cada familia. Y todos ellos tienen el mismo nombre: All-new SANTA FE. La nueva Santa Fe fue diseñada para adaptarse a todas las familias modernas, independientemente de cómo se vean.'
      },
      {
        title: 'Celebrar la unión.',
        description: 'La Nueva Santa Fe te da la comodidad y libertad para disfrutar de esos preciados momentos con tus seres queridos.'
      }
    ],
    caracteristics: [
      {
        title: 'Diseño',
        texts: [
          'Los autos familiares no necesariamente son aburridos.',
          'Sus ópticas compuestas también le dan un atractivo de alta tecnología, lista para aventuras familiares.',
          'Una amplia parrilla cromada en cascada y llantas de aleación de 18 pulgadas conforman el diseño de la Nueva SANTA FE.'
        ],
        carousel: [
          {
            name: 'frente',
            img: 'Models/SANTAFE/santafe-disenio-frente_xvw3m7',
            text: 'Parrilla en cascada con ópticas y faros DRL'
          },
          {
            name: 'luces',
            img: 'Models/SANTAFE/santafe-disenio-luces-delanteras-led_aqumi0',
            text: 'Luces altas, bajas y antinieblas LED'
          },
          {
            name: 'ruedas',
            img: 'Models/SANTAFE/santafe-disenio-techo-panoramico_mody6y',
            text: 'Techo panorámico'
          },
          {
            name: 'asientos',
            img: 'Models/SANTAFE/santafe-disenio-interior_tmzwsx',
            text: 'Espacio interior refinado y cómodo'
          }
        ]
      },
      {
        title: 'Performance',
        texts: [
          '2.4 MPi Nafta: Con una potencia máxima de 172cv a 6,000 rpm y un torque máxima de 22.9 kgm a 4,000 rpm. Transmisión automática de 6 velocidades, 4x2.',
          '2.2 CRDi Diesel: Con una potencia máxima de 200cv a 3,800 rpm y un torque máxima de 45.0 kgm entre 1,750 y 2,750rpm. Transmisión automática de 8 velocidades, 4x4.',
          'HTRAC es un sistema electrónico de tracción en todas las ruedas que detecta automáticamente la velocidad del auto y las condiciones de la ruta para controlar el frenado entre las ruedas izquierda y derecha y aplicar el control activo de torque entre los ejes delantero y trasero, garantizando la estabilidad.'
        ],
        carousel: [
          {
            name: 'motor',
            img: 'Models/SANTAFE/santafe-performance-motor-nafta_lnsfef',
            text: '2.4 MPi Nafta'
          },
          {
            name: 'suspension',
            img: 'Models/SANTAFE/santafe-performance-motor-diesel_ilxkcu',
            text: '2.2 CRDi Diesel'
          },
          {
            name: 'motor',
            img: 'Models/SANTAFE/santafe-performance-htrac_zdmnk8',
            text: 'HTRAC 4x4'
          },
          {
            name: 'eco',
            img: 'Models/SANTAFE/santafe-performance-eco_szqp7o',
            text: 'Modo ECO'
          },
          {
            name: 'sport',
            img: 'Models/SANTAFE/santafe-performance-sport_bctdpo',
            text: 'Modo SPORT'
          },
          {
            name: 'comfort',
            img: 'Models/SANTAFE/santafe-performance-comfort-smart_gsv51z',
            text: 'Modo COMFORT'
          },
        ]
      },
      {
        title: 'Seguridad',
        texts: [
          'La Nueva SANTA FE incluye características de seguridad. Safe Exit Assist (SEA). Rear Occupant Alert (ROA)',
          'El sistema de 6 airbag comprende dos airbags frontales, dos airbags de impacto lateral en la parte delantera y dos airbags de cortina que corren a lo largo por ambos lados.',
          'Sensores en el paragolpes trasero advierten cuando un vehículo se aproxima en el área de punto ciego. Por la izquierda o derecha en las maniobras de marcha atras'
        ],
        carousel: [
          {
            name: 'hac',
            img: 'Models/SANTAFE/santafe-seguridad-6-airbags_zhrx6v',
            text: '6 Airbags'
          },
          {
            name: 'esc',
            img: 'Models/SANTAFE/santafe-seguridad-freno_jq9yte',
            text: 'Hill Start Assist y Downhill Break Control'
          },
          {
            name: 'abs',
            img: 'Models/SANTAFE/santafe-seguridad-advertencia-colision-trasera_l8agcg',
            text: 'Advertencia de Colisión Trasera de Tráfico Cruzado'
          },
          {
            name: 'sea y roa',
            img: 'Models/SANTAFE/santafe-seguridad-sea_norrxb',
            text: 'Safe Exit Assist / Rear Ocupant Alert'
          }
        ]
      },
      {
        title: 'Confort',
        texts: [
          'Tablero digital de 7". Muestra información importante para el conductor, como el estado de las funciones de seguridad, el rango de combustible restante y el consumo',
          'Pantalla multimedia de 8". La pantalla touch flotante integra multimedia y funciones de conectividad que incluyen Android Auto ™ y Apple CarPlay ™.',
          'Cortinas para ventanas traseras. Manija de agarre para 3er fila. Climatizador bi-zona. Plegado On-Touch.'
        ],
        carousel: [
          {
            name: 'baul inteligente',
            img: 'Models/SANTAFE/santafe-confort-porton-trasero-inteligente_mg2v4w',
            text: 'Portón trasero inteligente'
          },
          {
            name: 'cargador',
            img: 'Models/SANTAFE/santafe-confort-cargador-celular-inalambrico_ws5ozz',
            text: 'Cargador de celular inalámbrico'
          },
          {
            name: 'plegar asientos',
            img: 'Models/SANTAFE/santafe-confort-asientos-electricos-con-ajuste-lumbar_tuxc04',
            text: 'Asientos eléctricos con ajuste lumbar'
          },
          {
            name: 'freno electrico',
            img: 'Models/SANTAFE/santafe-confort-freno-estacionamiento-electrico_rxssdd',
            text: 'Freno de estacionamiento eléctrico'
          }
        ]
      }
    ]
  },
]