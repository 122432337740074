import '../../styles/components/client.scss';
import { AdvancedImage } from "@cloudinary/react"
import { Card } from "react-bootstrap"
import { cloudinaryImg } from "../../helpers/cloudinary"

export default function ClientCard({ name, image, opinion }) {
  const opinionTexts = opinion.split('. ')
  return (
    <Card className="client-card">
      <AdvancedImage className='client-img' cldImg={cloudinaryImg(image)} />
      <Card.Body className="card-body">
        <Card.Title>{name}</Card.Title>
        {
          opinionTexts.map((elem, ind) => {
            return <Card.Text key={ind}>{elem}</Card.Text>
          })
        }
      </Card.Body>
    </Card>
  )
}