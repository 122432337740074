import { Col, Dropdown, DropdownButton, Offcanvas } from "react-bootstrap";
import { navItems } from "../../helpers/constants";
import '../../styles/components/nav.scss';

export default function SideNav({ showOffcanvas, handleShowOffcanvas }) {
  return (
    <Offcanvas className='offcanvas-custom' show={showOffcanvas} onHide={handleShowOffcanvas} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="list-options">
          {navItems.map((elem, index) => {
            return (
              <div className="title-option" key={index}>
                <DropdownButton
                  className="custom-dropdown"
                  title={elem.name}
                  id={elem.name}
                  key={index}
                  drop={window.innerWidth > 768 ? "down-centered" : "start"}
                >
                  {elem.options.map((optionGroup, opInd) => {
                    return (
                      <Col className="col-options" key={`${index}-${opInd}`}>
                        <Dropdown.Header>{optionGroup.name}</Dropdown.Header>
                        {optionGroup.optionList.map((option, lastInd) => {
                          return (
                            <Dropdown.Item key={`${index}-${opInd}-${lastInd}`} href={option.href}>
                              {option.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Col>
                    );
                  })}
                </DropdownButton>
              </div>
            );
          })}
          <li key={'turnos'} className="title-option">Turnos</li>
        </ul>
      </Offcanvas.Body>
    </Offcanvas>
  )
}