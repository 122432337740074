import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImg } from "../helpers/cloudinary";
import '../styles/components/model-page.scss'; 

export default function ModelCard({ name, img, redirectUrl }) {
  const handleClick = () => {
    window.location.href = redirectUrl
  }

  return (
    <div className="model-card" onClick={handleClick}>
      <AdvancedImage cldImg={cloudinaryImg(img)} alt={name} />
      <p>{name}</p>
    </div>
  )
}