import { clients } from '../../helpers/constants';
import '../../styles/components/company.scss';
import '../../styles/components/client.scss';
import ClientCard from './ClientCard';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function Company() {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section');

    if (section === 'trayectoria') {
      scrollToSection('trayect');
    } else if (section === 'clientes') {
      scrollToSection('clients');
    }
  }, [location.search]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const offset = element ? element.offsetHeight + 1 * 16 : 0;
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top -
          offset,
      })
    }
  };

  return (
    <div className="content-page">
      {/* <div className="page-indicator">
        <h5>Empresa</h5>
      </div> */}
      <div className="title">
        <h2>Nuestra empresa</h2>
      </div>
      <div id='trayect' className="trayect">
        <div className="subtitle">
          <h4>Trayectoria</h4>
        </div>
        <div className="timeline">
          <div className="timeline__event animated fadeInUp delay-1s timeline__event--type1">
            <div className="timeline__event__icon">
              <i className="lni-slim"></i>
              <div className="timeline__event__date">
                1993
              </div>
            </div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">
                Nacimiento
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp timeline__event--type2">
            <div className="timeline__event__icon">
              <i className="lni-cake"></i>
              <div className="timeline__event__date">
                1998
              </div>
            </div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">
                Apertura venta zona Pacheco y Tigre
              </div>
            </div>
          </div>
          <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type3">
            <div className="timeline__event__icon ">
              <i className="lni-cake"></i>
              <div className="timeline__event__date">
                2000
              </div>
            </div>
            <div className="timeline__event__content ">
              <div className="timeline__event__title">
                Concesionario LIDER EN VENTAS
              </div>
            </div>
          </div>
          <div className="timeline__event animated fadeInUp delay-2s timeline__event--type4">
            <div className="timeline__event__icon">
              <i className="lni-burger"></i>
              <div className="timeline__event__date">
                2010
              </div>
            </div>
            <div className="timeline__event__content">
              <div className="timeline__event__title">
                Apertura taller oficial
              </div>
            </div>
          </div>
        </div>
        <p className='description'>
          FYC MOTORS SRL es una empresa familiar con más de 30 años en zona norte. <br />Nacida en mayo de 1993 comercializando vehículos HYUNDAI.
          Estableciéndose en Martinez y San Isidro, desarrollo sus actividades y afianzándose hasta llegar a ser el concesionario líder en ventas del país durante varios años.<br />
          Dentro de nuestra estructura, contamos con una vasta experiencia en comercialización de usados y un departamento de gestoría integral para todos los trámites propios y de terceros.
        </p>
      </div>
      <div id="clients" className="clients">
        <div className="subtitle">
          <h4>Clientes</h4>
        </div>
        <div className="customers">
          {
            clients.map((elem, ind) => {
              return <ClientCard key={ind} name={elem.name} image={elem.img} opinion={elem.opinion} />
            })
          }
        </div>
      </div>
    </div>
  )
}