import '../../styles/components/model-page.scss';
import { useParams } from 'react-router-dom';
import { cloudinaryImg } from '../../helpers/cloudinary';
import { AdvancedImage } from "@cloudinary/react";
import { models_description } from '../../helpers/constants';
import { Tab, Tabs } from 'react-bootstrap';
import TabModel from '../../atomic/TabModel';
import CarouselStatic from '../../atomic/CarouselStatic';

export default function Model() {
  const { modelo } = useParams()
  const model = models_description.find((elem) => elem.id.toLowerCase() === modelo.toLowerCase())

  return (
    <div className="content-page">
      {
        model &&
        <>
          <CarouselStatic images={model.images} title={model.name} />
          <div className="model-info">
            <div className="text-container">
              <div className="model-description">
                <ul>
                  {
                    model.highlights.map((elem, index) => {
                      return <li key={index}>
                        <div className="title-highlight">
                          <h6>{elem.title}</h6>
                        </div>
                        <div className="text-highlight">
                          <p>{elem.description}</p>
                        </div>
                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
            <AdvancedImage cldImg={cloudinaryImg(model.img)} alt='model' />
          </div>
          <div className="caracteristics">
            <Tabs
              defaultActiveKey="style"
              className="model-tabs"
            >
              <Tab eventKey="style" title="Diseño">
                <TabModel model={model} tab='Diseño' />
              </Tab>
              <Tab eventKey="performance" title="Performance">
                <TabModel model={model} tab='Performance' />
              </Tab>
              <Tab eventKey="security" title="Seguridad">
                <TabModel model={model} tab='Seguridad' />
              </Tab>
              <Tab eventKey="confort" title="Confort">
                <TabModel model={model} tab='Confort' />
              </Tab>
            </Tabs>
          </div>
        </>
      }
    </div>
  )
}