import '../../styles/components/office.scss';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { concesionarioCarousel, tallerCarousel } from "../../helpers/constants";
import CarouselImages from "../../atomic/CarouselImages";

export default function Dealership() {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section');

    if (section === 'concesionario') {
      scrollToSection('concesionario');
    } else if (section === 'taller') {
      scrollToSection('taller');
    }
  }, [location.search]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      const offset = element ? element.offsetHeight + 0 * 16 : 0;
      window.scrollTo({
        behavior: 'smooth',
        top:
          element.getBoundingClientRect().top -
          offset,
      })
    }
  };

  return (
    <div className="content-page">
      <div className="title">
        <h2>Centros de atencion</h2>
      </div>
      <div id='concesionario' className="concesionario">
        <div className="subtitle">
          <h4>Concesionario</h4>
        </div>
        <div className="wrap-info">
          <h6>San Isidro</h6>
          <div className="line-wrap">
            <div className="office-carousel">
              <CarouselImages carouselImg={concesionarioCarousel} />
            </div>
            <div className="info-details">
              <p className='description'>
                Nuestra concesionaria ubicada en el nucleo del barrio de Acassuso.
              </p>
              <iframe
                title="Concesionario"
                className='maps-iframe'
                loading="lazy"
                allowFullScreen={false}
                referrerPolicy="no-referrer-when-downgrade"
                src={process.env.REACT_APP_MAPS_SAN_ISIDRO}>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <div id="taller" className="taller">
        <div className="subtitle">
          <h4>Taller</h4>
        </div>
        <div className="wrap-info">
          <h6>Troncos del Talar</h6>
          <div className="line-wrap">
            <div className="office-carousel">
              <CarouselImages carouselImg={tallerCarousel} />
            </div>
            <div className="info-details">
              <p className='description'>
                Nuestro taller ubicado en Tigre.
              </p>
              <iframe
                title="Taller"
                className='maps-iframe'
                loading="lazy"
                allowFullScreen={false}
                referrerPolicy="no-referrer-when-downgrade"
                src={process.env.REACT_APP_MAPS_TALLER}>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}