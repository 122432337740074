import { Col, Dropdown, DropdownButton } from "react-bootstrap";
import { navItems } from "../../helpers/constants";
import '../../styles/components/nav.scss';
import { AdvancedImage } from "@cloudinary/react";
import { cloudinaryImg } from "../../helpers/cloudinary";
import { AiOutlineMenu } from 'react-icons/ai';
import { useState } from "react";
import SideNav from "./SideNav";

export default function Nav() {

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleShowOffcanvas = () => {
    showOffcanvas ? setShowOffcanvas(false) : setShowOffcanvas(true);
  };

  const goHome = () => {
    window.location.href = '/'
  }

  return (
    <nav className="nav-web">
      <AdvancedImage cldImg={cloudinaryImg('Home/logoHyundai')} alt='FYC' onClick={goHome} />
      {window.innerWidth <= 768 ? (
        <AiOutlineMenu className="toggle-offcanvas-button" onClick={handleShowOffcanvas} />
      ) : (
        <div className="nav-shortcuts">
          <ul className="list-options">
            {navItems.map((elem, index) => {
              return (
                <div className="title-option" key={index}>
                  <DropdownButton
                    className="custom-dropdown"
                    title={elem.name}
                    id={elem.name}
                    key={index}
                    drop="down-centered"
                  >
                    {elem.options.map((optionGroup, opInd) => {
                      return (
                        <Col className="col-options" key={`${index}-${opInd}`}>
                          <div>
                            <Dropdown.Header>{optionGroup.name}</Dropdown.Header>
                            {optionGroup.optionList.map((option, lastInd) => {
                              return (
                                <Dropdown.Item key={`${index}-${opInd}-${lastInd}`} href={option.href}>
                                  {option.name}
                                </Dropdown.Item>
                              );
                            })}
                          </div>
                        </Col>
                      );
                    })}
                  </DropdownButton>
                </div>
              );
            })}
            <li key={'turnos'} className="title-option">Turnos</li>
          </ul>
        </div>
      )}
      <SideNav showOffcanvas={showOffcanvas} handleShowOffcanvas={handleShowOffcanvas} />
    </nav>
  );
}
