import { AdvancedImage } from "@cloudinary/react";
import { Carousel } from "react-bootstrap";
import { cloudinaryImg } from "../helpers/cloudinary";
import '../styles/components/model-page.scss';

export default function CarouselStatic({ images, title }) {
  return (
    <Carousel
      slide={false}
      className="static-carousel"
    >
      {
        images.map((elem, index) => {
          return <Carousel.Item key={index}>
            <AdvancedImage cldImg={cloudinaryImg(elem)} alt={elem} />
            <Carousel.Caption>
              <h1>{title}</h1>
            </Carousel.Caption>
          </Carousel.Item>
        })
      }
    </Carousel>
  )
}