import CarouselImages from "../../atomic/CarouselImages";
import { carouselImg } from "../../helpers/constants";
import Models from "./Models";
import '../../styles/components/landing.scss';

export default function Landing() {
  return (
    <div className="landing">
      <h1>Bienvenido a FYC Motors SRL</h1>
      <CarouselImages carouselImg={carouselImg} />
      <Models />
    </div>
  )
}