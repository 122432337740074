import '../../styles/components/footer.scss';
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineMail } from 'react-icons/ai';
import { LiaToolsSolid } from 'react-icons/lia';
import { BiSolidCar } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import { AdvancedImage } from '@cloudinary/react';
import { cloudinaryImg } from '../../helpers/cloudinary';

export default function Footer() {

  const handleMapsConcesionaria = () => {
    const googleMapsUrl = `https://maps.app.goo.gl/BrLeAbrDmFKnCF6f6`;
    window.open(googleMapsUrl, "_blank");
  }

  const handleMapsTaller = () => {
    const googleMapsUrl = `https://maps.app.goo.gl/6eSH6QE3XfRARvGo7`;
    window.open(googleMapsUrl, "_blank");
  }

  const handleMail = () => {
    const emailAddress = "sanisidro@fycmotors.com.ar";
    window.location.href = `mailto:${emailAddress}`;
  }

  const handleInstagram = () => {
    const instagramUsername = 'fyc.motors';
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobileDevice) {
      const instagramAppURL = `instagram://user?username=${instagramUsername}`;
      window.location.href = instagramAppURL;
    } else {
      const instagramWebURL = `https://www.instagram.com/${instagramUsername}`;
      window.open(instagramWebURL, '_blank');
    }
  };

  // const handleTwitter = () => {
  //   const twitterUsername = 'FYCmotors';
  //   const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  //   if (isMobileDevice) {
  //     const twitterAppURL = `twitter://user?screen_name=${twitterUsername}`;
  //     window.location.href = twitterAppURL;
  //   } else {
  //     const twitterWebURL = `https://twitter.com/${twitterUsername}`;
  //     window.open(twitterWebURL, '_blank');
  //   }
  // };

  const handleFacebook = () => {
    const facebookUsername = 'profile.php?id=100094091571996';
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobileDevice) {
      const facebookAppURL = `fb://${facebookUsername}`;
      window.location.href = facebookAppURL;
    } else {
      const facebookWebURL = `https://www.facebook.com/${facebookUsername}`;
      window.open(facebookWebURL, '_blank');
    }
  };

  const handleWhatsapp = () => {
    const phoneNumber = `+549113831254`;
    const message = 'Hola, quiero saber mas!'

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobileDevice) {
      window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    } else {
      const whatsappWebURL = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(whatsappWebURL, '_blank');
    }
  }

  return (
    <footer className="footer-web">
      <div className="logo">
        <AdvancedImage cldImg={cloudinaryImg('Home/logoHyundai')} alt='Logo' />
        <div className="texts">
          <ul>
            <li onClick={handleMapsConcesionaria}><BiSolidCar /> Av. del Libertador 15595, San Isidro</li>
            <li onClick={handleMapsTaller}><LiaToolsSolid /> Juan B. Justo 2547, Troncos del Talar</li>
          </ul>
        </div>
      </div>
      <div className="footer-contact">
        <div className="contact">
          <span>Contacto</span>
          <div className="details">
            <AiOutlineMail className='mail-icon' onClick={handleMail} />
            <BsWhatsapp className='wpp-icon' onClick={handleWhatsapp} />
          </div>
        </div>
        <div className="redes">
          <span>Redes</span>
          <div className="icons">
            <AiOutlineInstagram className='social-icon' onClick={handleInstagram} />
            <AiOutlineFacebook className='social-icon' onClick={handleFacebook} />
            {/* <AiOutlineTwitter className='social-icon' onClick={handleTwitter} /> */}
          </div>
        </div>
      </div>
    </footer>
  )
}