import './App.scss';
import { Route, Routes } from "react-router-dom";
import Nav from "./components/nav/Nav";
import Landing from "./components/landing/Landing";
import Footer from './components/landing/Footer';
import Company from './components/company/Company';
import Dealership from './components/office/Dealership';
import Model from './components/models/Model';
import Used from './components/used/Used';

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route exact path='/' element={<Landing />} />
        <Route exact path='/empresa' element={<Company />} />
        <Route exact path='/sucursal' element={<Dealership />} />
        <Route exact path='/modelos/:modelo' element={<Model />} />
        <Route exact path='/usados' element={<Used />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
